import company from './company.json';
import addCompany from './companyAdd.json';
import site from './site.json';
import addSite from './addSite.json';
import dashboard from './dashboard.json';
import supportTree from './supportTree.json';
import app from './app.json';
import supportReport from './supportReport.json';
import support from './support.json';
import user from './user.json';
import UserAdd from './addUser.json';
import userCompany from './userCompany.json';
import addUserCompany from './addUserCompany.json';
import userSite from './userSite.json';
import addUserSite from './addUserSite.json';
import companyMapping from './companyMapping.json';
import addMapping from './addMapping.json';
import subCompany from './subCompny.json';
import addSubCompany from './addSubCompany.json';
import banner from './banner.json';
import supportGroup from './supportGroup.json';
import activityLogs from './activityLogs.json';
import userSession from './userSession.json';
import notification from './notification.json';
import projects from './projects.json';
import tekaController from './tekaController.json';
import addTekaController from './addTekaController.json';
import addProjects from './addProjects.json';
import version from './version.json';
import header from './header.json';
import footer from './footer.json';
import supportLogs from './supportLogs.json';
import myProfile from './myProfile.json';
import supportGruoupLog from './supportGruoupLog.json';
import supportRequestLog from './supportRequestLog.json';
import supportCalender from './supportCalender.json';
import supportTechnician from './supportTechnician.json'
import accessLevel from './accessLevel.json'
import workItemEdit from './workItemEdit.json'
import workItem from './workItem.json'
import workItemCreate from './workItemCreate.json'
import changePassword from './changePassword.json'
import dashboardSiteMap from './dashboardSiteMap.json'
import siteMap from './siteMap.json'
import myCalendar from './myCalendar.json'
import routing from './routing.json'

export default {
    routing,
    company,
    addCompany,
    site,
    addSite,
    dashboard,
    supportTree,
    app,
    supportReport,
    support,
    user,
    UserAdd,
    userCompany,
    addUserCompany,
    userSite,
    addUserSite,
    companyMapping,
    addMapping,
    subCompany,
    addSubCompany,
    banner,
    supportGroup,
    activityLogs,
    userSession,
    notification,
    projects,
    tekaController,
    addTekaController,
    addProjects,
    version,
    header,
    footer,
    myProfile,
    supportLogs,
    supportGruoupLog,
    supportRequestLog,
    supportCalender,
    supportTechnician,
    accessLevel,
    workItemEdit,
    workItem,
    workItemCreate,
    changePassword,
    dashboardSiteMap,
    siteMap,
    myCalendar
};

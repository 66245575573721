import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import Layout from '../Layout';
import Loader from "../loader";
import { Tooltip, Switch } from 'antd';
import { fetchUsersDetails, fetchSitesByUserId, fetchAllRoles, fetchusersitedata, fetchSubCompaniesByUserId, fetchUserSiteById, fetchUpdatedUserSiteRelation, fetchAllSiteData, fetchSitesByCompanyId, fetchUsersWithRoles, fetchUserMenudata, fetchAllCompaniesdata } from "../../Redux/Api/fetch.api";
import { getallusers, getusersandroles, getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { getsitesbyuserid, postusersitedata, updateusersitedata } from '../../Redux/Actions/UserSiteAction';
import { getallsitesdetails, getsitesbycompanyid } from '../../Redux/Actions/SiteActions';
import { getallroles } from '../../Redux/Actions/RolesActions';
import { getsubcompanybyuserid } from '../../Redux/Actions/UserCompanyAction';
import { getallcompanies } from '../../Redux/Actions/CompanyActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { useTranslation } from "react-i18next";
import Title from "../Title";

const AddUserSite = () => {
    const { t} = useTranslation('addUserSite');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [allUsers, setAllUsers] = useState([]);
    //const [allSites, setAllSites] = useState([]);
   // const [allSitesData, setAllSitesData] = useState([]);
    const [sitesData, setSitesData] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [userId, setUserId] = useState();
    const [subCompanyId, setSubCompanyId] = useState();
    const [companyId, setCompanyId] = useState();
    const [roleId, setRoleId] = useState();
    const [siteId, setSiteId] = useState();
    const [allowNotification, setAllowNotification] = useState(false);
    const [allowSMS, setAllowSMS] = useState(false);
    const [isActive, setisActive] = useState(true)
    const [allowUpdate, setAllowUpdate] = useState(false);
    const [allowMaintenance, setAllowMaintenance] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [comRoleName, setComRoleName] = useState(null);
    const [allSubCompanies, setAllSubCompanies] = useState([]);
    const loggedUserId = localStorage.getItem('UserId')
    const [allCompaniesData, setAllCompaniesData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    let history = useHistory();
    const location = useLocation();
    const origin = location.state?.origin || "UserSite"; 
    const [disable, setDisable] = useState(false);
    let pageButton = t("Update")
    if (!currentId) {
        pageButton = t("Save")
    }

    useEffect(() => {      
        getRolesDetails();      
        getalluserswithroles();
        getallcompaniesdata();
        getUserDetails();
    }, [])
   
    useEffect(() => {
        getCompanysiteDetails();
    }, [subCompanyId]);

    useEffect(() => {   
        getallcompaniesdata();
            getSubCompaniesDetails();        
    }, [userId]);

    const handleToggle = () => {
        
        setAllowNotification(!allowNotification);
    };

    const handleToggleSMS = () => {
        setAllowSMS(!allowSMS);
    };

    const handleToggleUpdate = () => {
        setAllowUpdate(prev => !prev);
        //  setAllowUpdate(!allowUpdate);
    };

    const handleToggleMaintenance = () => {
        setAllowMaintenance(prev => !prev);
    };

    useEffect(() => {
        setLoading(true);
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setIsEdit(true);
            setLoading(true);
            fetchUserSiteById(urlData[3]).then(
                response => {
                    
                    setUserId(response.data.data.userId)
                    setSiteId(response.data.data.siteId)
                    setRoleId(response.data.data.roleId)
                    setSubCompanyId(response.data.data.subCompanyId)
                    setAllowNotification(response.data.data.allowNotification)
                    setAllowSMS(response.data.data.allowSMS)
                    setAllowMaintenance(response.data.data.allowMaintenance)
                    setAllowUpdate(response.data.data.allowUpdate)
                    setisActive(response.data.data.isActive)                    
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [currentId])

    const getallcompaniesdata = () => {
        setLoading(true)
        fetchAllCompaniesdata()
            .then(response => {
                dispatch(getallcompanies(response.data))
                setAllCompaniesData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const getalluserswithroles = () => {
        setLoading(true)
        fetchUsersWithRoles({
            UserId: loggedUserId
        })
            .then(response => {
                dispatch(getusersandroles(response.data))
                setAllUsers(response.data.data)

            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 4000)
    }

    const getUserDetails = () => {        
        fetchUserMenudata(loggedUserId)
            .then(response => {                
                dispatch(getuserroleandmenu(response.data))
                setComRoleName(response.data.data.userRoleDetails[0].roleName)
                if (response.data.data.userRoleDetails[0].roleName == "Admin")
                    setCompanyId(response.data.data.userCompanyDetails[0].companyId)               
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }    
   
    const getSubCompaniesDetails = () => {
        fetchSubCompaniesByUserId(userId)
            .then(response => {
                dispatch(getsubcompanybyuserid(response.data))
                setAllSubCompanies(response.data.data.companyUserdata)
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getCompanysiteDetails = () => {

        fetchSitesByCompanyId(subCompanyId ? subCompanyId : companyId)
            .then(response => {
                dispatch(getsitesbycompanyid(response.data))
                setSitesData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getRolesDetails = () => {
        fetchAllRoles()
            .then(response => {
                dispatch(getallroles(response.data))
                setAllRoles(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }
   

    const addUserSiteData = async () => {       
        setLoading(true)
        fetchusersitedata({
            UserId: userId,
            SiteId: siteId,
            RoleId: 8,
            SubCompanyId: subCompanyId ? subCompanyId : companyId,
            AllowNotification: allowNotification,
            AllowSMS: allowSMS,
            AllowUpdate: allowUpdate,
            AllowMaintenance: allowMaintenance
        }).then(response => {            
            dispatch(postusersitedata(response.data))
            if (!response.data.success) // False 
            {
                toast.error(t("UserSiteRelationalreadycreated"), {
                    className: "toastSuccess",
                },);
                setLoading(false);
                setDisable(false)
            }
            else {
                toast.success(t("UserSiteRelationCreatedsuccessfully"), {
                    className: "toastSuccess",
                },);
                history.push('/UserSite');
                setLoading(false);
                setDisable(true)
            }
        })
            .catch((err) => {
                console.log("Err: ", err);
            });

    }

    const updateUserSiteData = async () => {       
        setLoading(true)
        fetchUpdatedUserSiteRelation({
            UserSiteId: currentId,
            UserId: userId,
            SiteId: siteId,
            RoleId: 8,
            SubCompanyId: subCompanyId ? subCompanyId : companyId,
            AllowNotification: allowNotification,
            AllowSMS: allowSMS,
             AllowUpdate: allowUpdate,
            AllowMaintenance: allowMaintenance
        }).then(response => {          
            dispatch(updateusersitedata(response.data))
            if (!response.data.success) // False 
            {
                toast.error(t("CompanyRelationAlreadyAdded"), {
                    className: "toastSuccess",
                },);
                setLoading(false);
                setDisable(false)
            }
            else {
                toast.success(t("CompanyRelationupdatedsuccessfully"), {
                    className: "toastSuccess",
                },);
                history.push('/UserSite');
                setLoading(false);
                setDisable(true)
            }
        })

    }

    return (
        <div>
            <Title title={`${isEdit ? "Edit" : "Add"} User Site`} />

            {comRoleName == null ? <Loader /> : comRoleName && comRoleName != "SuperAdmin" && comRoleName && comRoleName != "Developer"
                && comRoleName && comRoleName != "Admin" ? <NotAuthorised></NotAuthorised> :
                <Layout>
                    <section className="User_section common ">
                        <Toaster />
                        {loading ? <Loader /> : ''}
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">
                                    <Link className="backBTN" to={origin === "UserPage" ? "/User" : "/UserSite"}>
                                        <img src={Arrow_leftbrown}></img>
                                        {t("Back")}
                                    </Link>
                                </h4>

                            </div>
                            <div className="content-area">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group wk-select">
                                                <label>{t("User")} <span className="required-fields"> * </span></label>
                                                <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                    value={userId}
                                                    key={userId}
                                                    onChange={(e) => {
                                                        setUserId(e.target.value)
                                                        setSubCompanyId("")

                                                    }}
                                                >
                                                    <option selected="">{t("SelectUser")}</option>
                                                    {
                                                        allUsers && allUsers.map(user =>
                                                            <option key={user.userId} value={user.userId}>
                                                                {user.lastName + " " + user.firstName} </option>
                                                        )
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                        {comRoleName == "SuperAdmin" ?
                                            <div className="col-md-4">
                                                <div className="form-group wk-select">
                                                    <label>{t("SubCompany")} <span className="required-fields"> * </span></label>
                                                    <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                        value={subCompanyId}
                                                        key={subCompanyId}
                                                        onChange={(e) =>
                                                            setSubCompanyId(e.target.value)

                                                        }
                                                    >
                                                        <option selected="">{t("SelectSubCompany")}</option>

                                                        {
                                                            allSubCompanies && allSubCompanies.map(sub =>
                                                                <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                                    {sub.subCompanyName} </option>
                                                            )
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                            :
                                            <div className="col-md-4">
                                                <div className="form-group wk-select">
                                                    <label>{t("SubCompany")} <span className="required-fields"> * </span></label>
                                                    <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                        value={companyId}
                                                        key={companyId}
                                                        disabled
                                                        onChange={(e) =>
                                                            setCompanyId(e.target.value)

                                                        }
                                                    >
                                                        <option selected="">{t("SelectSubCompany")}</option>

                                                        {
                                                            allCompaniesData && allCompaniesData.map(company =>
                                                                <option key={company.companyId} value={company.companyId}>
                                                                    {company.companyName} </option>
                                                            )
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                        }
                                        <div className="col-md-4">
                                            <div className="form-group wk-select">
                                                <label>{t("Site")} <span className="required-fields"> * </span></label>
                                                <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                    value={siteId}
                                                    key={siteId}
                                                    onChange={(e) => setSiteId(e.target.value)}
                                                >
                                                    <option selected="">{t("SelectSite")}</option>
                                                    {
                                                        sitesData && sitesData.map(site =>
                                                            <option key={site.siteId} value={site.siteId}>
                                                                {site.siteName} </option>
                                                        )
                                                    }
                                                </select>

                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div class="settings-container position-relative">
                                                <h2 class="container-title">{t("PropertiesforSupportRequest")}</h2>
                                            <div className="d-flex flex-wrap gap-10">

                                                <div className="alarm-toggle--wrap d-inline-block">

                                                    <Switch
                                                        id="allowNotifications"
                                                        className="ms-2"
                                                        checked={allowNotification}
                                                        onChange={handleToggle}
                                                    />

                                                    <label className="alarm-toggle-label ml-2">{t("AllowNotifications")}</label>
                                                </div>

                                                <div className="alarm-toggle--wrap d-inline-block">

                                                    <Switch
                                                        id="allowSMS"
                                                        className="ms-2"
                                                        checked={allowSMS}
                                                        onChange={handleToggleSMS}
                                                    />

                                                    <label className="alarm-toggle-label ml-2">{t("AllowSMS")}</label>
                                                    </div>

                                                    <div className="alarm-toggle--wrap d-inline-block">

                                                        <Switch
                                                            id="allowUpdate"
                                                            className="ms-2"
                                                            checked={allowUpdate}
                                                            onChange={handleToggleUpdate}
                                                        />

                                                        <label className="alarm-toggle-label ml-2">{t("AllowUpdate")}</label>
                                                    </div>

                                                    <div className="alarm-toggle--wrap d-inline-block">

                                                        <Switch
                                                            id="allowMaintenance"
                                                            className="ms-2"
                                                            checked={allowMaintenance}
                                                            onChange={handleToggleMaintenance}
                                                        />

                                                        <label className="alarm-toggle-label ml-2">{t("AllowMaintenance")}</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        
                                        



                                        <div className="col-md-12 mt-4">
                                            {comRoleName == "SuperAdmin" ?
                                                userId && userId != "Select User" && siteId && siteId != "Select Site" && subCompanyId && subCompanyId != "Select Sub Company" ? <button className="btn btn-primary"
                                                    onClick={currentId == undefined || currentId == null || currentId == isNaN ? addUserSiteData : updateUserSiteData}>{pageButton}</button>
                                                    : <button className="btn btn-primary" disabled
                                                        onClick={currentId == undefined || currentId == null || currentId == isNaN ? addUserSiteData : updateUserSiteData}>{pageButton}</button>
                                                :
                                                userId && userId != "Select User" && siteId && siteId != "Select Site"  && companyId && companyId != "Select Sub Company" ? <button className="btn btn-primary"
                                                    onClick={currentId == undefined || currentId == null || currentId == isNaN ? addUserSiteData : updateUserSiteData}>{pageButton}</button>
                                                    : <button className="btn btn-primary" disabled
                                                        onClick={currentId == undefined || currentId == null || currentId == isNaN ? addUserSiteData : updateUserSiteData}>{pageButton}</button>
                                            }
                                            <Link className="btn btn-bordered ml-2" to="/UserSite">{t("Cancel")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            }
        </div>
    )

}
export default connect()(AddUserSite);

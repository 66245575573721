import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import Loader from "../loader";
import TechnicianGroupList from "./TechnicianGroupList";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from "react-i18next";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Tooltip } from "antd";
import {
    getAllAssignedTechniciansByGroup, putSupportDataOnCalendar, getSubCompaniesByUserId, postSupportEvent, putSupportEvent, deleteSupportEvent, getAllEventByGroupId
      
} from "../../Redux/Api/fetch.api";
import TechnicianIcon from "../../assets/images/customer-support.png";
import { Scheduler } from 'smart-webcomponents-react/scheduler';
import '../../../src/calendar.css';
import clockIcon from "../../assets/images/clock-icon.svg";
import Clock from "../Support/Clock";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Title from "../Title";




const SupportTechnician = () => {
    const { t } = useTranslation('supportCalender');
    const scrollButtonsPosition = 'far';
    const schedulerRef = useRef(); 
    const [isCollapsed, setIsCollapsed] = useState(false); 
    const [technicians, setTechnicians] = useState([]); 
    const [events, setEvents] = useState([]); 
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [groupId, setGroupId] = useState(null);
    const [siteId, setSiteId] = useState(null);
    const [supportId, setSupportId] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const history = useHistory();
    const [selectedTechnicians, setSelectedTechnicians] = useState([]);
    const [resourceTechnicians, setResourceTechnicians] = useState([]); 
    const timeZoneName = "Eastern Standard Time"
    const currentTimeIndicator = true;
    const [statuses, setStatuses] = useState([]);
   
    let siteid;
    let supportid;
    let groupid;
    const views = ['day', 'week', 'month'];
   
   
    const today = new Date();
   
    today.setHours(0, 0, 0, 0);
    const yesterdayStart = new Date(today);
    yesterdayStart.setDate(today.getDate() - 1);
   
    // #region Handle TimeZone
    dayjs.extend(utc);
    dayjs.extend(timezone);
      
    useEffect(() => {       
         // Parse query parameters from the URL
            const queryParams = new URLSearchParams(location.search);         
            const siteIdFromUrl = queryParams.get('siteId');
            const supportIdFromUrl = queryParams.get('supportId');
            const groupIdFromUrl = queryParams.get('groupId');       
             // Check if `groupIdFromUrl` is a valid number and greater than 0
        if (!isNaN(groupIdFromUrl) && groupIdFromUrl > 0) {                      
                     groupid = groupIdFromUrl
                    siteid = siteIdFromUrl;
            supportid = supportIdFromUrl;     
             // Update state with the groupId obtained from URL
            setGroupId(groupIdFromUrl);            
                    getAllAssignedTechnician(groupIdFromUrl)
                    initializeEventScheduler(groupIdFromUrl)
                    history.replace({ search: queryParams.toString() });
                  }                
    }, [location.search]);
   
    useEffect(() => {
        const schedulerElement = schedulerRef.current.nativeElement;

        // Add event listener to allow drag over events (to prevent default browser behavior)
        schedulerElement.addEventListener('dragover', (e) => e.preventDefault());

        // Add event listener to handle the drop event (for external drag-and-drop functionality)
        schedulerElement.addEventListener('drop', handleDrop);

        // Add event listeners for tooltips
        document.addEventListener('mouseover', handleMouseOver);
        document.addEventListener('mouseout', handleMouseOut);

        // Cleanup: remove event listeners when the component is unmounted
        return () => {
            schedulerElement.removeEventListener('dragover', (e) => e.preventDefault());
            schedulerElement.removeEventListener('drop', handleDrop);

            // Clean up event listeners on component unmount
            document.removeEventListener('mouseover', handleMouseOver);
            document.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    useEffect(() => {       
        filterEvents();
    }, [selectedTechnicians, events]);

    const filterEvents = () => {
          // Filter the events based on selected technicians
    // If no technicians are selected, include all events
        const filteredEvents = events.filter(event =>
            selectedTechnicians.length === 0 || selectedTechnicians.some(selected => selected.id === event.clientId)
        );
          // Update the data source of the scheduler with the filtered events
        schedulerRef.current.dataSource = new window.Smart.DataAdapter({
            dataSource: filteredEvents,
            dataSourceType: 'array',
            dataFields: getSchedulerDataFields()
        });
    };

   
    // Function to format the header of a timeline based on orientation and date details
    const timelineHeaderFormatFunction = (date, orientation, isHeaderDetails, dateValue) => {
         // Check if the timeline orientation is horizontal
        if (orientation === 'horizontal') {
            const segments = dateValue.split(' ');
             // If the dateValue contains exactly two segments (e.g., day number and day name)
            if (segments.length === 2) {
                // Return formatted HTML with the day name and day number in separate divs
                return `<div>${segments[1]}</div><div class="header-day-number">${segments[0]}</div>`;
            }
        }
        return dateValue;
    };

    const resources = [
        {
            label: 'Technician',
            value: 'userId',
            dataSource: resourceTechnicians
        }
    ];

    const initializeEventScheduler = async (groupid) => {
       
        try {
           
            // Fetch events from the API
            const response = await getAllEventByGroupId(groupid);

            // Convert the event times to the user's local time zone
            const eventsInLocalTime = response.data.data.map(event => ({
                ...event,
                // Ensure StartDate and EndDate are formatted in the user's local time
                StartDate: dayjs(event.StartDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss'),
                EndDate: dayjs(event.EndDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss')
            }));

            // Initialize the scheduler with the converted event data
            schedulerRef.current.dataSource = new window.Smart.DataAdapter({
                dataSource: eventsInLocalTime, // Use converted event times
                dataSourceType: 'array',
                dataFields: getSchedulerDataFields()
            });

            // Set the events in the local state
            setEvents(eventsInLocalTime);

            // Apply any custom calendar settings (if needed)
            await customCalendarSettings();
        } catch (error) {
            // Handle any errors that occur during the event fetching process
            console.error("Error fetching support events:", error);
        }
    };


    // Function to apply custom settings to the calendar scheduler component
    const customCalendarSettings = () => {       
        const scheduler = document.querySelector('smart-scheduler');

         // Listen for the edit dialog opening event on the scheduler
        scheduler.addEventListener('editDialogOpen', async function (event) {
              // Get the editors used in the edit dialog
            const editors = event.detail.editors;
              // If there are no editors, exit the function
            if (!editors) {
                return;
            }
             // Get references to various editors and elements in the dialog
            const schedulerEvent = event.detail.item,
                allDayEditor = editors.allDay,
                repeatEditor = editors.repeat,
                statusEditor = editors.status,
                labelEditor = editors.label,
                descriptionEditor = editors.description,
                notificationEditor = editors.notifications,
                disableLabel = editors.label,
                disableStartDate = editors.dateStart,
                disableEndDate = editors.dateEnd,
                disableBackgroundColor = editors.backgroundColor,
                resourceDataEditor = editors.resourceData,
                resourcesEditor = editors.resources;

            // Hidden all properties 
            repeatEditor.classList.add('smart-hidden');
            allDayEditor.classList.add('smart-hidden');           
            notificationEditor.classList.add('smart-hidden');
            resourceDataEditor.classList.add('smart-hidden');
            resourcesEditor.classList.add('smart-hidden');

             // Set custom placeholders for the label and description editors
            labelEditor.querySelector('.smart-element').placeholder = 'Enter Support Note...';
            descriptionEditor.querySelector('.smart-element').placeholder = 'Enter Support Description...';
            statusEditor.querySelector('#schedulerstatusLabel').textContent = "Sub Companies"


            // Get today's date without time for comparison
            const today_ = new Date();
            today_.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Get the start date from the scheduler event and remove the time component
            const startDate = new Date(schedulerEvent.dateStart);
            startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Check if the start date is before today or if the event falls within restricted hours
            if (startDate < today_) {
                // Hide Ok footer 
                event.detail.target.footerPosition = 'none';
                disableLabel.classList.add('smart-disabled');
                descriptionEditor.classList.add('smart-disabled');
                disableStartDate.classList.add('smart-disabled');
                disableEndDate.classList.add('smart-disabled');
                disableBackgroundColor.classList.add('smart-disabled');
            } else {
                // Show Ok footer 
                event.detail.target.footerPosition = 'bottom';
                disableLabel.classList.remove('smart-disabled');
                descriptionEditor.classList.remove('smart-disabled');
                disableStartDate.classList.remove('smart-disabled');
                disableEndDate.classList.remove('smart-disabled');
                disableBackgroundColor.classList.remove('smart-disabled');
            }
        });
    }

    function getSchedulerDataFields() {
        return [
            { name: 'id', map: 'eventId', dataType: 'number' },
            { name: 'label', map: 'label', dataType: 'string' },
            { name: 'dateStart', map: 'startDate', dataType: 'date' }, // Change to 'date'
            { name: 'dateEnd', map: 'endDate', dataType: 'date' },     // Change to 'date'
            { name: 'description', map: 'description', dataType: 'string' },
            { name: 'allDay', map: 'isAllDay', dataType: 'bool' },
            { name: 'backgroundColor', map: 'backGroundColor', dataType: 'string' },
            { name: 'isDrag', map: 'isDrag', dataType: 'bool' },
            { name: 'userId', map: 'clientId', dataType: 'number' },
            { name: 'orderId', map: 'orderId', dataType: 'number' },
            { name: 'groupName', map: 'supportGroup', dataType: 'string' },
            { name: 'conference', map: 'conference', dataType: 'string' },
            { name: 'status', map: 'status', dataType: 'string' }
        ];
    }

    const getAllAssignedTechnician = async (groupIdFromUrl) => {
     
        try {
            const response = await getAllAssignedTechniciansByGroup(groupIdFromUrl);  // Fetch events from API
           
            // Map the unique clients as resources for the scheduler (technicians)
            const technicians_ = response.data.data.map(resource => ({
                label: resource.userName,  // Display name for the technician
                id: resource.clientId,   // Unique ID for the technician,
                supportGroup: resource.supportGroup,
                orderId: resource.orderId,
                image: TechnicianIcon
            }));

            setTechnicians(technicians_);
            setResourceTechnicians(technicians_);

        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors encountered
        }
    };


    const updateSupportData =  async() => {      
        // Prepare the payload for the API request based on the eventItem object
        const payload = {
            supportId: supportid,
            siteId: siteid
        };
        try {
            await putSupportDataOnCalendar(payload); // Handle Update
        } catch (err) {
            // Log the error and display an error toast notification
            console.error("Error:", err);          
        }        
    };

    const toggleCollapse = () => {
        setIsCollapsed(prevState => !prevState);  // Toggle the collapse state
    };

    const handleTechnicianSelection = (id) => {
        const technician = technicians.find(t => t.id === id);

        setSelectedTechnicians(prev => {
            const updatedSelected = prev.some(t => t.id === id)
                ? prev.filter(t => t.id !== id) // If technician is already selected, remove it
                : [...prev, technician]; // If not selected, add it

           //  Update resource technicians with the new selected technicians
            if (updatedSelected && updatedSelected.length > 0) {
                setResourceTechnicians(updatedSelected);
            }
            else {
                setResourceTechnicians(technicians);
            }

            return updatedSelected; // Return the updated state
        });
    };

    // Function to handle the drag start event for external tasks (technicians)
    const handleDragStart = (event, label, bgColor, userId, supportGroup) => {
        const data = JSON.stringify({ label, bgColor, userId, supportGroup });  // Serialize data as JSON
        event.dataTransfer.setData('application/json', data);  // Set the data in JSON format
    };

    const handleDrop = (event) => {
        const data = event.dataTransfer.getData('application/json');  // Retrieve the label of the dropped task
        const { label, bgColor, userId } = JSON.parse(data);
        const scheduler = schedulerRef.current;        
        if (scheduler) {          
                // Get the drop location date/time based on the coordinates of the drop event
                const dropDate = scheduler.getDateFromCoordinates(event.clientX, event.clientY);
                const startDate = new Date(dropDate);
                startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Validation checking 
            if (startDate >= yesterdayStart) {
                    const newEvent = {
                        label: label,  // Set the label of the event (technician's name)
                        dateStart: dropDate,  // Start time of the new event
                        dateEnd: new Date(dropDate.getTime() + 3600000), // Set duration to 1 hour
                        backgroundColor: bgColor,  // Background color for the event
                        userName: label,
                        userId: userId,
                        description: ""
                    };

                    // Add the new event to the existing list of events
                    setEvents((prevEvents) => {
                        const updatedEvents = [...prevEvents, newEvent];
                        scheduler.dataSource = []; 
                        schedulerRef.current.dataSource = new window.Smart.DataAdapter({
                            dataSource: updatedEvents,
                            dataSourceType: 'array',
                            dataFields: getSchedulerDataFields()
                        });

                        // Insert data into the databse on drag and drop
                        refreshData('insert', newEvent);
                        return updatedEvents;
                    });
                }
                else {
                    toast.error(t("Pleasenoteupdates"), {
                        className: "toastError",
                    });
            }          
        }       
    };

    const refreshData = async (action, eventItem) => {      
        let response;
        let successMessage;
        let errorMessage;
        if (!eventItem.userId) {
            toast.error("Event not added", {
                className: "toastError",
            });
            return; 
           
        }
        // Prepare the payload with the exact date/time as selected by the user
        const payload = {
            EventId: eventItem.id,
            EventName: eventItem.label,
            // Send date as it is without any conversion
            StartDate: dayjs(eventItem.dateStart).format('YYYY-MM-DDTHH:mm:ss'),
            EndDate: dayjs(eventItem.dateEnd).format('YYYY-MM-DDTHH:mm:ss'),
            Description: eventItem.description,
            IsAllDay: eventItem.allDay,
            SupportUserGroupId: eventItem.userId,
            BackGroundColor: eventItem.backgroundColor,
            Conference: eventItem.conference,
            GroupID: groupid!= null ? groupid : groupId
        };

        try {
          
            let response;
            switch (action) {
                case 'insert':
                    response = await postSupportEvent(payload); // Handle Insert
                    successMessage = t("Eventcreatedsuccessfully")
                    errorMessage = t("Noeventsadded")
                   
                    break;
                case 'update':
                    response = await putSupportEvent(payload); // Handle Update
                    successMessage = t("Eventupdatedsuccessfully")
                    errorMessage = t("Eventnotfoundorcouldnotbeupdated")
                    await initializeEventScheduler(groupId); // Refresh event data

                    break;
                case 'delete':
                  
                    response = await deleteSupportEvent(eventItem.id); // Handle Delete
                    successMessage = t("Eventdeletedsuccessfully")
                    errorMessage = t("Eventnotfound.")
                    break;
                default:
                    throw new Error("Unknown action type");
            }
            if (response.data.success) {
                toast.success(successMessage, {
                    className: "toastSuccess",
                });
               
               
            } else {
                toast.error(errorMessage, {
                    className: "toastError",
                });
                // Refresh event data

            }                                             
            if (siteid > 0 && supportid > 0) {
                await updateSupportData();
            }                                   
            await initializeEventScheduler(groupid); // Refresh event data
        } catch (err) {
            console.error("Error:", err);
           
        }
    };

   // Function to customize the appearance of an event in the scheduler
    const eventTemplate = function (eventContent, eventObj) {
        const scheduler = this;
          // Wrapper for event content
        let eventContentWrapper = eventContent.querySelector('.event-content-wrapper');
        if (!eventContentWrapper) {
            eventContentWrapper = document.createElement('div');
            eventContentWrapper.classList.add('event-content-wrapper');
        }
         // Get or create various elements for the event display
        let label = eventContent.querySelector('label'),
            time = eventContent.querySelector('div.time'),
            orderNoContainer = eventContent.querySelector('.support-order-no-container'),
           // descLabel = eventContent.querySelector('.desc'),
            orderIdCircle = eventContent.querySelector('.support-Orderid-circle'),
            groupName = eventContent.querySelector('.support-GroupName'),
            img = eventContent.querySelector('img'),
            inviteButton = eventContent.querySelector('.invite-link-button'); // Check for existing button

        // Label for the technician's name or event
        if (!label) {
            label = document.createElement('label');
            label.classList.add('time', 'support-technician-name');
            eventContentWrapper.appendChild(label);
        }

        // Time label
        if (!time) {
            time = document.createElement('div');
            time.classList.add('time', 'support-small-time');
            eventContentWrapper.appendChild(time);
        }
       
        // Group Name
        if (!groupName) {
            groupName = document.createElement('div');
            groupName.classList.add('support-GroupName');
            eventContentWrapper.appendChild(groupName);
        }

        // Order No container for aligning Order ID and Circle
        if (!orderNoContainer) {
            orderNoContainer = document.createElement('div');
            orderNoContainer.classList.add('support-order-no-container');
            eventContentWrapper.appendChild(orderNoContainer);
        }

        // Order ID circle
        if (!orderIdCircle) {
            orderIdCircle = document.createElement('div');
            orderIdCircle.classList.add('support-Orderid-circle');
            orderNoContainer.appendChild(orderIdCircle);
        }

        // Set text content
        label.textContent = eventObj.label;

        let startFrom = new Intl.DateTimeFormat(scheduler.locale, {
            hour: scheduler.hourFormat,
            minute: scheduler.minuteFormat
        }).format(eventObj.dateStart);

        let endTo = new Intl.DateTimeFormat(scheduler.locale, {
            hour: scheduler.hourFormat,
            minute: scheduler.minuteFormat
        }).format(eventObj.dateEnd);

        time.textContent = `${startFrom} - ${endTo}`;

        // Set the Order ID circle color and text
        orderIdCircle.style.backgroundColor = eventObj.orderIdColor || '#FF5722'; // Default color
        orderIdCircle.textContent = eventObj.orderId;

        // Check if "Order Id:" label already exists
        let orderIdLabel = orderNoContainer.querySelector('.support-order-id-label');
        if (!orderIdLabel) {
            orderIdLabel = document.createElement('span');
            orderIdLabel.classList.add('support-order-id-label');
            orderIdLabel.textContent = "Order Id : ";
            orderNoContainer.insertBefore(orderIdLabel, orderIdCircle); // Insert before the circle
        }

        // Set additional information
       // descLabel.textContent = "Description : " + (eventObj.description || "N/A");
        groupName.textContent = "Group : " + (eventObj.groupName || "N/A");

        // Image handling
        if (eventObj.img) {
            if (!img) {
                img = document.createElement('img');
                eventContentWrapper.appendChild(img);
            }
            img.src = eventObj.img;
        } else if (img) {
            img.remove();
        }

        // Invite button
        if (!inviteButton) {
            //inviteButton = document.createElement('button');
            //inviteButton.classList.add('invite-link-button', 'ant-btn', 'ant-btn-primary'); // Ant Design classes

            //// Create image element
            //const image = document.createElement('img');
            //image.src = ShareIcon; // Use the provided image
            //image.alt = 'Invite via link'; // Alt text for accessibility
            //image.style.width = '20px'; // Adjust width if needed
            //image.style.marginRight = '5px'; // Space between image and text

            //inviteButton.appendChild(image);
            //inviteButton.appendChild(document.createTextNode(' Send Email'));

            //inviteButton.onclick = () => {
            //    const inviteLink = eventObj.inviteLink || '#'; // Use the invite link from eventObj
            //    window.open(inviteLink, '_blank'); // Open link in a new tab
            //};

            //eventContentWrapper.appendChild(inviteButton);
        }

        if (!eventContentWrapper.parentElement) {
            eventContent.appendChild(eventContentWrapper);
        }

        return eventContent;
    }

    const handleSchedulerUpdate = async (detail, isDrag = false) => {
       

        const { item, itemDateRange } = detail;
        if (itemDateRange.dateStart && itemDateRange.dateEnd) {
            // Get the start date from the scheduler event and remove the time component
            const startDate = new Date(itemDateRange.dateStart);
            startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Validation checking 
            if (itemDateRange.dateStart >= yesterdayStart) {
                item.dateStart = itemDateRange.dateStart;
                item.dateEnd = itemDateRange.dateEnd;
                item.isDrag = isDrag;
                refreshData('update', item);
                if (siteid > 0 && supportid > 0) {
                   
                    await updateSupportData();
                }
            }
            else {
                toast.error(t("Pleasenote"), {
                    className: "toastError",
                });
                // Re-initialize the event scheduler after each successful action
                await initializeEventScheduler(groupid);
            }
        }
    }

    const handleMouseOver = (event) => {
        // Check if the mouse is over an event element
        if (event.target.classList.contains('smart-scheduler-event-content')) {
            const content = event.target;
            const label = content.querySelector('.support-technician-name')?.textContent || 'No label';
            const time = content.querySelector('.support-small-time')?.textContent || 'No time';
           // const desc = content.querySelector('.desc')?.textContent || 'No desc';
            const groupName = content.querySelector('.support-GroupName')?.textContent || 'No group';

            const tooltip = document.createElement('div');
            tooltip.className = 'event-tooltip';
            tooltip.innerHTML = `
            Technician : ${label}<br>
            Assigned Time : ${time}<br>
          
            ${groupName}
        `; // Use <br> for line breaks

            document.body.appendChild(tooltip);

            // Position the tooltip
            const rect = content.getBoundingClientRect();
            tooltip.style.left = `${rect.left}px`;
            tooltip.style.top = `${rect.bottom + 5}px`; // Position below the event
            tooltip.classList.add('visible');
        }
    };
   
    // Remove tooltips when the mouse leaves the event area
    const handleMouseOut = (event) => {
        // Remove tooltips when mouse leaves the event area
        if (event.target.classList.contains('smart-scheduler-event-content')) {
            const tooltips = document.querySelectorAll('.event-tooltip');
            tooltips.forEach(tooltip => tooltip.remove());
        }
    };
    
    const handleDragEnd = (event) => handleSchedulerUpdate(event.detail, true);
    const handleResizeEnd = (event) => handleSchedulerUpdate(event.detail);
    const handleItemUpdate = (event) => refreshData('update', event.detail.item);
    const handleItemRemove = (event) => refreshData('delete', event.detail.item);
   
    return (
        <div>
            <Title title="Support Technician"></Title>
         <>
                <Layout>
                    <div style={styles.supportcontainer} className="supportcontainer">
                        <div className="support-Cont-child" style={{ ...styles.navbar, width: isCollapsed ? '80px' : '22%', minWidth: isCollapsed ? '80px' : '22%' }}>
                            <div style={styles.header}>
                                {!isCollapsed && <h4>{t("SupportTechnicians")}</h4>}

                                <Tooltip title={isCollapsed ? t("OpenMenu") : t("Close Menu")}>
                                    <button onClick={toggleCollapse} className="support-technicians-toggleButton">
                                        {isCollapsed ? <MenuOpenIcon fontSize="small" /> : <MenuIcon fontSize="small" />}
                                    </button>
                                </Tooltip>
                            </div>

                            {!isCollapsed && (
                                <div style={{ overflowY: 'auto', maxHeight: '600px' }}> {/* Scrollable Container */}
                                    <TechnicianGroupList
                                        technicians={technicians}
                                        loading={false}
                                        selectedTechnicians={selectedTechnicians}
                                        handleTechnicianSelection={handleTechnicianSelection}
                                        handleDragStart={handleDragStart}
                                    />
                                </div>
                            )}

                           
                        </div>

                        <div className="d-flex flex-wrap clock-support">
                            <div className="time-zone w-100">
                                <div className="clock-wrap"><img src={clockIcon} alt="" /><Clock timeZoneName={timeZoneName} currentUtcOffset="-6.00" isDST="true" />
                                </div>
                            </div>

                            <div style={styles.scheduler} className="scheduler-calendar"                             
                            >
                                <Scheduler
                                    ref={schedulerRef}
                                    view="week"
                                    id="scheduler"
                                    onDragEnd={handleDragEnd}
                                    onResizeEnd={handleResizeEnd}
                                    onItemUpdate={handleItemUpdate}
                                    onItemRemove={handleItemRemove}                                
                                    currentTimeIndicator={currentTimeIndicator}
                                    eventTemplate={eventTemplate}
                                    timelineHeaderFormatFunction={timelineHeaderFormatFunction}
                                    resources={resources}
                                    statuses={statuses.length ? statuses : [{ label: "Loading...", value: "loading" }]}
                                    // Disable all creation-related features
                                   enableEventCreation={false}
                                    disableContextMenu={true}
                                
                                    // Prevent cell clicks from creating events
                                    onCellClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                       return false;
                                    }}
                                    // Additional props to disable interactions
                                    disableSelection={true}
                                    disableWindowEditor={true}
                                    onDragStart={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        return false;
                                    }}
                                    views={["week", "month", "day"]}
                                />
                            </div>                           
                        </div>

                    </div>
                        <Toaster />
                </Layout>
        </>
        </div>
    )



}

const styles = {
    supportcontainer: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '10px',
        
    },

    navbar: {
        transition: 'width 0.3s ease',  // Smooth transition for collapsing
        padding: '20px',  // Padding inside the navbar
        borderRight: '2px solid #ccc',  // Border separating the navbar and scheduler
        overflow: 'hidden',  // Hide overflow content when collapsed
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px 0 0 10px', // Rounded corners on the left side
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',  // Align items to the start and end
        marginBottom: '15px',  // Add space below the header
    },
    scheduler: {
        width: '100%',  // Adjust width based on collapsed navbar width (80px when collapsed)
   
        padding: '20px',  // Add padding around the scheduler
        borderRadius: '0 10px 10px 0', // Rounded corners on the right side
        
    }
};
export default connect()(SupportTechnician);

import React, { useState } from 'react';
import { Tooltip, Button } from "antd"; // Import Tooltip and Button from Ant Design
//import { CheckBox } from 'smart-webcomponents-react/checkbox';
import '../../../src/supportTechnicianList.css';
import { useTranslation } from "react-i18next";

const TechnicianGroupList = ({
    technicians,
    loading,
    selectedTechnicians,
    handleTechnicianSelection,
    handleDragStart,
    handleAddTechnician, // Function to handle adding a technician
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation('supportTechnician');

    // Function to filter technicians based on the search term
    //const filteredTechnicians = technicians.filter(technician =>
    //    technician.label.toLowerCase().includes(searchTerm.toLowerCase())
    //);

    // Function to group and sort technicians
    const groupAndSortTechnicians = (technicians) => {
       
        if (!Array.isArray(technicians) || technicians.length === 0) {
            return {};
        }

        const grouped = technicians.reduce((acc, item) => {
            if (!item || !item.supportGroup) {
                return acc; // Skip invalid items
            }

            const group = item.supportGroup;
            if (!acc[group]) {
                acc[group] = []; // Create a new array for this supportGroup
            }
            acc[group].push(item); // Add the item to the group

            return acc;
        }, {});

        // Sort each group by orderId in ascending order
        for (const group in grouped) {
            grouped[group].sort((a, b) => Number(a.orderId) - Number(b.orderId));
        }

        return grouped;
    };

    const groupedTechnicians = groupAndSortTechnicians(technicians);

    // Function to get a unique color for each technician based on their id
  

    const getUniqueColor = (id) => {
        // Generate a color based on the ID
        const hue = id * 137 % 360; // Use the golden ratio for a visually distributed hue
        return `hsl(${hue}, 70%, 50%)`; // Adjust saturation and lightness as needed
    };

    //const getUniqueColor = (id) => {

    //    const index = id % colors.length; // Use modulo to cycle through colors
    //    return colors[index];
    //};
   
    // #region Random calendar colors 
    // Array of random colors to apply to the technician task items
    const colors = ['#007bff', '#28a745', '#dc3545', '#ffc107', '#17a2b8', '#f8f9fa', '#343a40'];
    // #endregion

    return (
        <>
            {loading ? (
                <p>{t("LoadingTechnicians")}</p>
            ) : Object.keys(groupedTechnicians).length === 0 ? (
                <p>{t("Notechniciansfound")}</p>
            ) : (
                Object.entries(groupedTechnicians).map(([group, technicians]) => (
                    <div className="support-technicians-task-list" key={group}>
                        <div className="support-header-container">
                            <h2 className="support-header-title">{group}</h2>
                            <Tooltip title={t("Clicktoaddsupporttechnician")}>
                                <Button
                                    type="primary"
                                    className="support-add-button"
                                    shape="circle"
                                    icon={<span>+</span>} // Use a plus sign for the button
                                    onClick={() => window.open(`/CallOrder?group=${encodeURIComponent(group)}`, '_blank')} // Open URL in a new tab
                                />
                            </Tooltip>
                        </div>

                        {technicians.map((technician) => (
                            <div className="support-technicians" key={technician.id}> {/* Unique key for each technician */}                               
                                <Tooltip title={t("DragTechnicianandAssign")}>
                                    <div
                                        className="support-technicians-task"
                                        draggable="true"  // Enable drag for this item
                                        onDragStart={(e) => handleDragStart(e, technician.label, getUniqueColor(technician.id), technician.id, technician.supportGroup)}  // Handle drag start with unique color
                                        style={{
                                            backgroundColor: getUniqueColor(technician.id), // Assign unique color
                                            flex: 1,  // Make the div take up the remaining space
                                            display: 'flex', // Use flexbox for alignment
                                            alignItems: 'center', // Center items vertically
                                        }}
                                    >
                                        <div className="support-order-id-circle">
                                            {technician.orderId || "N/A"}
                                        </div>

                                        {technician.label}
                                    </div>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                ))
            )}
        </>
    );
};

export default TechnicianGroupList;


import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import Layout from '../Layout';
import Loader from "../loader";
import { postallsites, updatesitetypedata, getallsitesretention } from '../../Redux/Actions/SiteActions';
import { getallcompanies } from '../../Redux/Actions/CompanyActions';
import { fetchSiteById, fetchAllCompaniesdata, fetchProjectDetails, fetchTimeZones, fetchAllRetentiondata, fetchGroupDetails } from "../../Redux/Api/fetch.api";
import { getallprojects, getallTimeZones } from '../../Redux/Actions/AccessibilityActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { fetchUserMenudata } from "../../Redux/Api/fetch.api";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { Checkbox, Select } from "antd";
import { getgroupsdata } from '../../Redux/Actions/CallOrderActions';
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
const Option = Select.Option;
import { useTranslation } from "react-i18next";
import Title from "../Title";

const AddSite = () => {
    const { t } = useTranslation('addSite');
    const [siteid, setSiteId] = useState();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [ipAddress, setIPAddress] = useState("");
    const [count, setCount] = useState(0);
    const [addCount, setAddCount] = useState(0);
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    const [isActive, setisActive] = useState(true)
    const [allCompaniesData, setAllCompaniesData] = useState([]);
    const [disable, setDisable] = useState(false);
    const dispatch = useDispatch(); 
    const [companyId, setCompanyId] = useState();
    const [parentcompanyId, setParentCompanyId] = useState();
    const [groupId, setGroupId] = useState();
    const [retentionError, setRetentionError] = useState(false)
    const [siteRetentionId, setSiteRetentionId] = useState();
    const [ifUpdate, setifUpdate] = useState(false)
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
    const [userRoleData, setUserRoleData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [selectProjects, setSelectProjects] = useState([]);
    const [allAppData, setAllAppData] = useState([]);
    const [allGroupData, setAllGroupData] = useState([]);
    const [allRetentionData, setAllRetentionData] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [timeZoneId, setTimeZoneId] = useState(0);
    const [isDST, setIsDST] = useState(false)
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [allAddress, getAllAddress] = useState('')
    const [postalCodeDisable, setPostalCodeDisable] = useState(false);
    const [fieldValue, setFieldsValue] = useState(false);
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [addressError, setAddressError] = useState('');   
    let history = useHistory();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setLoading(true)
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setIsEdit(true);
            setLoading(true)
            fetchSiteById(urlData[3]).then(
                response => {                   
                    setName(response.data.data.siteName);
                    setSiteId(response.data.data.siteId)
                    setDescription(response.data.data.description)
                    setIPAddress(response.data.data.ipAddress)
                    setCount(response.data.data.description.length)
                    setAddress(response.data.data.address)
                    setLatitude(response.data.data.latitude)
                    setLongitude(response.data.data.longitude)
                    setCity(response.data.data.city)
                    setState(response.data.data.state)
                    setCountry(response.data.data.country)
                    setPostalCode(response.data.data.postalCode)
                    setCompanyId(response.data.data.resellerCompanyId)
                    setParentCompanyId(response.data.data.parentCompanyId == 0 ? "" : response.data.data.parentCompanyId )
                    setGroupId(response.data.data.supportGroupId == null ? "" : response.data.data.supportGroupId)
                    setTimeZoneId(response.data.data.timeZoneId)
                    setSiteRetentionId(response.data.data.siteRetentionId == null ? "" : response.data.data.siteRetentionId)
                    setIsDST(response.data.data.isDST)
                    setAddCount(response.data.data.address.length)  
                    { response.data.data.apps ? setSelectProjects(getProject(response.data.data.apps)) : "" }
                    setisActive(response.data.data.isActive)   
                    setifUpdate(true);
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [currentId])

    const handleChange = () => {
        debugger
        setisActive(!isActive)

    }
    const handleChangeIsDST = () => {
        setIsDST(!isDST)
    }

    useEffect(() => {
        getUserDetails();
        getallcompaniesdata();
        getallapps();
        getTimeZones();
        getallretentiondetails();
        getallgroups();
    }, [])


    const handleChangeProjects = (selected) => {

        const arr1 = []
        selected.map((item, i) => {
            allAppData.filter(datas => datas.name === selected[i].label).map(filter_data => {
                arr1.push(filter_data)
            })
        })
        setProjects(arr1)
        setSelectProjects(selected)

    }

    const getallapps = () => {
       
        setLoading(true)
        fetchProjectDetails()
            .then(response => {                
                dispatch(getallprojects(response.data))
                setAllAppData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const getallgroups = () => {

        setLoading(true)
        fetchGroupDetails()
            .then(response => {
                dispatch(getgroupsdata(response.data))
                setAllGroupData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const getallretentiondetails = () => {
        setLoading(true)
        fetchAllRetentiondata()
            .then(response => {
                dispatch(getallsitesretention(response.data))
                setAllRetentionData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const getTimeZones = () => {
       
        fetchTimeZones()
            .then(response => {
                
                dispatch(getallTimeZones(response.data))
                setTimeZones(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
    const getUserDetails = () => {
        fetchUserMenudata(loggedUserId)
            .then(response => {
              
                dispatch(getuserroleandmenu(response.data.data))
                setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0] && response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
    let pageButton = t("Update")
    if (!currentId) {
        pageButton = t("Save")
    }

    const getProjectIds = (selectedProjects) => {
       
        let projects = [];
        selectedProjects.map(Item => {
            return projects.push(Item.appId ? Item.appId : Item.value)
        })
        return projects;
    }
   
    const getProject = (userproject) => {
        let projects = [];
        userproject.forEach(element => {
            let selectedProjects = {
                disabled: undefined, key: element.appId, label: element.name,
                value: element.appId
            };
            projects.push(selectedProjects);
        });
        return projects;
    }
    const getallcompaniesdata = () => {
        setLoading(true)
        fetchAllCompaniesdata()
            .then(response => {
                dispatch(getallcompanies(response.data))
                setAllCompaniesData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const projectIds = getProjectIds(projects);
   
    const addAllSites = async (params) => {      
        if (!address) {
            setAddressError(t("Addressisrequired"));
            return;
        }
        else {
            setLoading(true)
            if (params.name) {
                const response = await axios.post(url.POST_ALLSITES, {
                    SiteName: params.name.trim(),
                    Description: params.description.trim(),
                    Address: address,
                    City: city,
                    State: state,
                    Country: country,
                    PostalCode: postalCode,
                    Latitude: latitude,
                    Longitude: longitude,
                    ResellerCompanyId: companyId == "Select Company" ? 0 : companyId,
                    SupportGroupId: groupId == "Select Group" || groupId == "" ? 0 : groupId,
                    ParentCompanyId: parentcompanyId == "Select Parent Company" || parentcompanyId == null || parentcompanyId == "" ? 0  : parentcompanyId,
                    Active: isActive,
                    Project: projectIds,
                    TimeZoneId: timeZoneId > 0 ? timeZoneId : null,
                    IsDST: timeZoneId > 0 ? isDST : null,
                    IPAddress: ipAddress != "" ? ipAddress : null,
                    SiteRetentionId: siteRetentionId
                }).then(response => {
                    dispatch(postallsites(response.data))
                    if (!response.data.success) // False 
                    {
                        toast.error(t("SiteAlreadyCreated"), {
                            className: "toastSuccess",
                        },);
                        setLoading(false);
                        setDisable(false)
                    }
                    else {
                        toast.success(t("SiteCreatedsuccessfully"), {
                            className: "toastSuccess",
                        },);
                        history.push('/Site');
                        setLoading(false);
                        setDisable(true)
                    }
                })
                    .catch((err) => {
                        console.log("Err: ", err);
                        setDisable(false)
                    });
            }
        }

    }

    const updateSiteType = async (params) => {   
        if (!address) {
            setAddressError(t("Addressisrequired"));
            return;
        }
        else {
            setLoading(true)
           
            const projectsIdsToUpdate = getProjectIds(projects && projects.length > 0 ? projects : selectProjects);
            if (params.name) {
                const response = await axios.put(url.UPDATE_SITE_DATA, {
                    SiteId: currentId,
                    SiteName: params.name.trim(),
                    Description: params.description.trim(),
                    Address: address,
                    City: city == null ? "" : city,
                    State: state == null ? "" : state,
                    Country: country == null ? "" :country,
                    PostalCode: postalCode == null ? "" : postalCode,
                    Latitude: latitude == null ? null : latitude,
                    Longitude: longitude == null ? null : longitude,
                    ResellerCompanyId: companyId == "Select Company" ? 0 : companyId,
                    SupportGroupId: groupId == "Select Group" || groupId == "" ? 0 : groupId,
                    ParentCompanyId: parentcompanyId == "Select Parent Company" || parentcompanyId == null || parentcompanyId == "" ? 0 : parentcompanyId,
                    isActive: isActive,
                    Project: projectsIdsToUpdate,
                    TimeZoneId: timeZoneId > 0 ? timeZoneId : null,
                    IsDST: timeZoneId > 0 ? isDST : null,
                    IPAddress: ipAddress != "" ? ipAddress : null,
                    SiteRetentionId: siteRetentionId == "Select Retention Days" ? 0 : siteRetentionId

                }).then(response => {
                    dispatch(updatesitetypedata(response.data))
                    if (!response.data.success) // False 
                    {
                        toast.error(t("SiteAlreadyCreated"), {
                            className: "toastSuccess",
                        },);
                        setLoading(false);
                        setDisable(false)
                    }
                    else {
                        toast.success(t("Site updated successfully"), {
                            className: "toastSuccess",
                        },);
                        history.push('/Site');
                        setLoading(false);
                        setDisable(true);
                    }
                })
                    .catch((err) => {
                        console.log("Err: ", err);
                        setDisable(false)
                    });
            }
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name ? name : '',
            description: description ? description : '',
            address: address ? address : '',
            ipAddress: ipAddress ? ipAddress : ''
           
        },

        validationSchema: Yup.object({
            name: Yup.string().required(t("PleaseEnterName")).matches(
                "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                t("PleaseentervalidName")),

            //address: Yup.string().required('Please Enter Address ').matches(
            //    "^(.|\s)*[a-zA-Z]+(.|\s)*$",
            //    "Please enter valid Address"
            //),

             description: Yup.string().required(t("PleaseEnterDescription")).matches(
                 "^(.|\s)*[a-zA-Z]+(.|\s)*$",
                 t("PleaseentervalidDescription")
             ),
            ipAddress: Yup.string()
                .matches(
                    /^(\d{1,3}\.){3}\d{1,3}$/,
                    t("PleaseenteravalidIPaddress")
    ),

        }),
        onSubmit: (values) => {
            if (!retentionError) {
                { currentId == undefined || currentId == null || currentId == isNaN ? addAllSites(values) : updateSiteType(values) };
            }
        }
    })

    const handleTimeZoneDropDownChange = (e) => {
        setTimeZoneId(e.target.value)
        if(timeZoneId == 0){
            setIsDST(false);
        }
    }

    const handleAddressChange = (value) => {
        if (value.length > 0) {
            setAddress(value)
            setAddressError('');
        }
        else {
            setAddress("")
            setAddressError(t("Addressisrequired"));
        }
    };
    const handleAddressSelect = async (address, placeId) => {
        setAddressError('');
        setAddress(address);
        getAllAddress(address);
        const results = await geocodeByAddress(address);
        const latlang = await getLatLng(results[0]);
        console.log("data", latlang.lat)
        setLatitude(latlang.lat)
        setLongitude(latlang.lng)
        geocodeByAddress('Montevideo, Uruguay')
            .then(results =>

                getLatLng(results[0]))
            .then(({ lat, lng }) =>
                //setLatitude({lat}),
                console.log('Successfully got latitude and longitude', { lat, lng }),

            );
        const [place] = await geocodeByPlaceId(placeId);

        const { long_name: postalCode = "" } =
            place.address_components.find((c) => c.types.includes("postal_code")) ||
            {};
        let city = "",
            country = "",
            state = ""


        for (const component of results[0].address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "locality": {
                    city = component.long_name;
                    break;
                }
                case "administrative_area_level_1": {
                    state = component.long_name;
                    break;
                }
                case "country": {
                    country = component.long_name;
                    break;
                }
            }
        }

        setPostalCodeDisable(!!postalCode);
        setFieldsValue({

            ["postal_code"]: postalCode,
            ["city"]: city,
            ["state"]: state,
            ["country"]: country,
        });
        { address != "" ? setCity(city) : '' }
        setPostalCode(postalCode)
        setCity(city)
        setState(state)
        setCountry(country)
        console.log("postal", postalCode)
        console.log("city", city)
        console.log("state", state)
        console.log("country", country)
    };

    const handleStateChangeForAddress = (e) => {
        if (e == "") {
            setCity("")
            setPostalCode("")
            setState("")
            setCountry("")
            setLatitude("")
            setLongitude("")
            getAllAddress("")
        }
    }

    const renderFuncForGooglePlaces = ({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
    }) => (
        <div>
            <Input
                id="selectAddress_1"
                //addonAfter={selectAfter("address_1", publicOrPrivate("address_1"))}
                placeholder={t('SearchPlaces')}
                size="large"
                {...getInputProps({
                    placeholder: t('SearchPlaces'),
                })}
                tabIndex={9}
                maxLength={255}
            />

            <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                    const style = suggestion.active
                        ? {
                            backgroundColor: "#f8f8f8",
                            cursor: "pointer",
                        }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                        };
                    return (
                        <div
                            {...getSuggestionItemProps(suggestion, {
                                style,
                            })}
                            key={index}
                        >
                            {suggestion.description}
                        </div>
                    );
                })}
            </div>
        </div>
    );
    return (
        <>
            <Title title={`${isEdit ? "Edit" : "Add"} Site`} />
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <Layout>
                    <section className="User_section common ">
                        <Toaster />
                        {loading ? <Loader /> : ''}
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">
                                    <Link className="backBTN" to="/Site">
                                        <img src={Arrow_leftbrown}></img>
                                        {t("Back")}
                                    </Link>
                                </h4>

                            </div>
                            {currentId ?
                                <div>
                                    <label className="mb-0">{t("SiteID")} : {siteid}</label>
                                </div> : ""
                            }
                            <div className="content-area">
                                <Form className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        if (!siteRetentionId) {
                                            setRetentionError(true);

                                        }
                                        return false;
                                    }} >

                                    <div className="col-lg-12 wk-form-grup">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("SiteName")} <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <Input name="name" className="form-control" placeholder={t("EnterName")}
                                                            type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setName(e.target.value) }}
                                                            onBlur={validation.handleBlur} value={validation.values.name}
                                                            invalid={validation.touched.name && validation.errors.name ? true : false}
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("ResellerCompany")} </label>
                                                    <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                        value={companyId}
                                                        key={companyId}

                                                        onChange={(e) => {
                                                            setCompanyId(e.target.value)

                                                        }}
                                                    >
                                                        <option selected="">{t("SelectCompany")}</option>
                                                        {
                                                            allCompaniesData && allCompaniesData.map(company =>
                                                                <option key={company.companyId} value={company.companyId}>
                                                                    {company.companyName} </option>
                                                            )
                                                        }
                                                    </select>

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("ParentCompany")} </label>
                                                    <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                        value={parentcompanyId}
                                                        key={parentcompanyId}

                                                        onChange={(e) => {
                                                            setParentCompanyId(e.target.value)

                                                        }}
                                                    >
                                                        <option selected="">{t("SelectParentCompany")}</option>
                                                        {
                                                            allCompaniesData && allCompaniesData.map(company =>
                                                                <option key={company.companyId} value={company.companyId}>
                                                                    {company.companyName} </option>
                                                            )
                                                        }
                                                    </select>

                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("Description")} <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <Input name="description" className="form-control site-description" placeholder={t("EnterDescriptionHere")}
                                                            type="textarea" maxLength="250"

                                                            onChange={(e) => {
                                                                validation.handleChange(e);
                                                                setCount(e.target.value.length);
                                                                setDescription(e.target.value.replace(/\s+/g, ' '))
                                                            }}
                                                            onBlur={validation.handleBlur} value={validation.values.description}
                                                            invalid={
                                                                validation.touched.description && validation.errors.description ? true : false
                                                            }
                                                        />
                                                        <small className="count text-left">{count}/250</small>
                                                        {validation.touched.description && validation.errors.description ? (
                                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("SupportGroup")} </label>
                                                    <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                        value={groupId}
                                                        key={groupId}
                                                       
                                                        onChange={(e) => {
                                                            setGroupId(e.target.value)

                                                        }}
                                                    >
                                                        <option selected="">{t("SelectGroup")}</option>
                                                        {
                                                            allGroupData && allGroupData.map(group =>
                                                                <option key={group.groupId} value={group.groupId}>
                                                                    {group.groupName} </option>
                                                            )
                                                        }

                                                    </select>

                                                </div>
                                            </div>

                                           


                                            

                                            <div class="col-lg-12">
                                                <div class="form-group autocomplete-places">
                                                    <label>{t("AddressAutocomplete")}<span className="required-fields"> * </span></label>
                                                    <div class="row">
                                                        <div className="col-md-12">
                                                            <PlacesAutocomplete
                                                                value={address}
                                                                onChange={(e) => {
                                                                    handleAddressChange(e), handleStateChangeForAddress(e)
                                                                }}
                                                                onSelect={handleAddressSelect}
                                                            >
                                                                {renderFuncForGooglePlaces}
                                                            </PlacesAutocomplete>
                                                            {addressError && <div style={{ marginTop: '10px', color: '#dc3545', fontsize: '0.875em'}}>{addressError}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {allAddress != '' || currentId ?
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t("City")} </label>
                                                                <div className="input-group">
                                                                    <input name="city" className="form-control disabled-input" placeholder={t("EnterCity")} type="text" maxLength={50}
                                                                        disabled onChange={(e) => { setCity(e.target.value) }} value={address != "" ? city : ''} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t("State")} </label>
                                                                <div className="input-group">
                                                                    <input name="stateData" className="form-control disabled-input" placeholder={t("EnterState")} type="text" maxLength={50}
                                                                        disabled onChange={(e) => { setState(e.target.value) }} value={state} />

                                                                </div>
                                                            </div>
                                                        </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t("Zip")} </label>
                                                                        <div className="input-group">
                                                                            <Input name="postalCodeData" className="form-control disabled-input" placeholder={t("EnterPostalCode")}
                                                                                disabled type="text" maxLength={12} value={postalCode}
                                                                            />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t("Country")} </label>
                                                                <div className="input-group">
                                                                    <input name="country" disabled className="form-control disabled-input" placeholder={t("EnterCountry")} type="text" maxLength={50} onChange={(e) => { setCountry(e.target.value) }} value={country} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t("Latitude")}</label>
                                                                <div className="input-group">
                                                                    <input name="country" className="form-control disabled-input" placeholder={t("EnterLatitude")}
                                                                        disabled type="text" maxLength={50} onChange={(e) => { setLatitude(e.target.value) }} value={latitude} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t("Longitude")} </label>
                                                                <div className="input-group">
                                                                    <input name="country" className="form-control disabled-input" placeholder={t("EnterLongitude")} type="text" maxLength={50}
                                                                        disabled onChange={(e) => { setLongitude(e.target.value) }} value={longitude} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                : ""}

                                           
                                                <div className="col-md-6 select-Role comdropdown mb-3">
                                                    <label>{t("SelectProjects")} </label>
                                                    <Select
                                                        placeholder={t("SelectProjects")}
                                                        mode="multiple"
                                                        labelInValue
                                                        tokenSeparators={[" ", ","]}
                                                        value={selectProjects}
                                                        onChange={handleChangeProjects}
                                                    >
                                                        {/* <option disabled value="0">Select Roles</option> */}
                                                        {allAppData && allAppData.map(project =>
                                                          project.dashBoardUrl != null ?
                                                                <Option key={project.appId} value={project.appId} >
                                                                    {project.name}
                                                                </Option> : ""
                                                        )}
                                                    </Select>

                                                </div>
                                                <div className="col-md-3 select-Role comdropdown mb-3">
                                                    <label>{t("SelectTimeZone")} </label>
                                                    <select className="form-select mb-0 flex-grow-1 filter-select" name="timeZones" aria-label=".form-select-lg example"
                                                            value={timeZoneId}
                                                            // placeholder="Select role"
                                                            key={timeZoneId}
                                                            onChange={(e) => {
                                                                handleTimeZoneDropDownChange(e);
                                                                // setTimeZoneId(e.target.value);
                                                            }}
                                                        >
                                                            <option value="0" selected="0">{t("SelectTimeZone")}</option>
                                                            {
                                                                timeZones && timeZones.map(timeZone =>
                                                                    <option key={timeZone.timeZoneId} value={timeZone.timeZoneId}>
                                                                        {timeZone.name} </option>
                                                                )
                                                            }
                                                        </select>

                                                </div>
                                                
                                            {timeZoneId && timeZoneId > 0?
                                                (<div className="col-md-3 select-Role comdropdown mt-5">
                                                    <label>  <input type="checkbox" className="check mr-1" name="isDST" checked={isDST} onChange={() => handleChangeIsDST()} ></input>
                                                        {t("ObservesDaylightSavingsTime")}</label>
                                                </div>
                                                ):""}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("IPAddress")} </label>
                                                    <div className="input-group">
                                                        <Input name="name" className="form-control" placeholder={t("EnterIPAddress")}
                                                            type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setIPAddress(e.target.value) }}
                                                            onBlur={validation.handleBlur} value={validation.values.ipAddress}
                                                            invalid={validation.touched.ipAddress && validation.errors.ipAddress ? true : false}
                                                        />
                                                        {validation.touched.ipAddress && validation.errors.ipAddress ? (
                                                            <FormFeedback type="invalid">{validation.errors.ipAddress}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>{t("RetentionDays")} <span className="required-fields"> * </span></label>
                                                    <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                        value={siteRetentionId}
                                                        key={siteRetentionId}

                                                        onChange={(e) => {
                                                            setSiteRetentionId(e.target.value)
                                                            if (e.target.value == "Select Retention Days") {
                                                                setRetentionError(true)
                                                            }
                                                            else {
                                                                setRetentionError(false)

                                                            }

                                                        }}
                                                    >
                                                        <option selected = "">{t("SelectRetentionDays")}</option>
                                                        {
                                                            allRetentionData && allRetentionData.map(site =>
                                                                <option key={site.siteRetentionId} value={site.siteRetentionId}>
                                                                    {site.retentionDays} </option>
                                                            )
                                                        }
                                                    </select>
                                                    {< small className="text-danger d-block mb-3">{!retentionError ? ' ' : t('PleaseSelectRetentionDays')}</small>}
                                                </div>
                                               
                                            </div>

                                            <div className="col-md-12 mb-6">
                                                
                                                <div className="form-checkbox ">
                                                    {loading && currentId == undefined || currentId == null || currentId == isNaN ?
                                                        <label>
                                                            <input type="checkbox" className="check mr-1" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> {t("Active")}</label> :

                                                        <label>  <input type="checkbox" className="check mr-1" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                                            {t("Active")}</label>}
                                                </div>

                                            </div>  
                                            <div className="col-md-12 mb-8">
                                                <button type="submit" className="btn btn-primary" disabled={disable}>{pageButton}</button>
                                                <Link className="btn btn-bordered ml-2" to="/Site">{t("Cancel")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </section>
                </Layout>
            }
            
        </>
    )

}
export default connect()(AddSite);

